
import { defineComponent } from "vue";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
import { code6 } from "./data";

export default defineComponent({
  name: "customized-content",
  components: {
    CodeHighlighter
  },
  setup() {
    return {
      code6
    };
  }
});
